
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Complete extends Vue {
  created () {
    if (
      !this.$store.state.hasStarted ||
      !this.$store.state.hasCompletedKeywordConfig ||
      !this.$store.state.hasCompletedRedaction
    ) {
      this.$router.push('/')
    }

    this.$mixpanelService.startTimedEvent('settings.conversationAnalytics.wizard.viewComplete', {}, true)
  }

  appDeactivated () {
    this.$mixpanelService.trackTimedEvent('settings.conversationAnalytics.wizard.viewComplete')
  }

  doClickTester () {
    this.$root.$emit('test-engine')
  }

  doClickLog () {
    this.$root.$emit('call-log')
  }
}
