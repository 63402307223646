
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  BButton,
  BFormCheckbox, BIconChevronDown,
  BTable,
  CollapsePlugin,
  FormInputPlugin,
  ListGroupPlugin,
  OverlayPlugin,
  TablePlugin,
  ToastPlugin
} from 'bootstrap-vue'
import { mixins } from 'vue-class-component'
import WebComponentAuthMixin from '@infinity/shared/mixins/web-component-auth'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import Installation from '@/models/installation'
import { InfinityStatus } from '@infinity/shared/helpers/api'
import InstallationRepo from '@/repos/installationRepo'
import { Filter, FilterCollection, FilterOperand } from '@infinity/shared/helpers/filter'
import { find, pull, filter } from 'lodash'
import KeywordSpottingConfig from '@infinity/ca-tester/models/keywordSpottingConfig'
import KeywordGroupConfig from '@infinity/ca-tester/models/keywordGroupConfig'
import KeywordConfig from '@infinity/ca-tester/models/keywordConfig'
import KeywordTransferRepo from '@/repos/keywordTransferRepo'
import { AuthUtil } from '@infinity/shared/utils/auth'

Vue.use(TablePlugin)
Vue.use(CollapsePlugin)
Vue.use(FormInputPlugin)
Vue.use(ListGroupPlugin)
Vue.use(OverlayPlugin)
Vue.use(ToastPlugin)

type TableField = { [k: string]: string }
type TableItem = { [k: string]: string | boolean | number | TableConfig }
type TableConfig = { fields: TableField[]; items: TableItem[] }

@Component({
  components: {
    BFormCheckbox,
    BButton,
    BIconChevronDown
  }
})
export default class KeywordTransfer extends mixins(WebComponentAuthMixin) {
  @Prop({ required: true, type: Object })
  caConfig!: CaConfig

  isLoading = false
  isSaving = false

  transferConfig = new CaConfig()

  installationQuery = ''
  selectedInstallation = new Installation()

  installations: Installation[] = []

  selectedGroups: KeywordGroupConfig[] = []
  selectedKeywords: KeywordConfig[] = []

  get tableConfig (): TableConfig {
    const items: TableItem[] = []

    for (const group of this.caConfig.getKeywordGroups()) {
      if (group.getStatus() === InfinityStatus.Ok) {
        const groupItem = {
          keywordGroupId: group.getId(),
          keywordGroupName: group.getName(),
          keywordCount: filter(group.getKeywords(), { status: InfinityStatus.Ok }).length,
          keywordsTableConfig: {
            fields: [
              { key: 'selected', label: '' },
              { key: 'phrase', label: 'Phrase' },
              { key: 'criteriaCount', label: 'Criteria Count' }
            ],
            items: []
          },
          _showDetails: true
        }

        const config = groupItem.keywordsTableConfig as TableConfig

        for (const keyword of group.getKeywords()) {
          if (keyword.getStatus() === InfinityStatus.Ok) {
            config.items.push(
              {
                keywordId: keyword.getId(),
                phrase: keyword.getPhrase(),
                criteriaCount: keyword.getKeywordCriteria().length,
                keywordGroupId: group.getId()
              }
            )
          }
        }

        items.push(groupItem)
      }
    }

    return {
      fields: [
        { key: 'selected', label: '' },
        { key: 'keywordGroupName', label: 'Group Name' },
        { key: 'keywordCount', label: 'Keyword Count' },
        { key: 'showKeywords', label: '' }
      ],
      items
    }
  }

  onGroupSelected (item: TableItem, index: number) {
    this.toggleGroup(item.keywordGroupId as number)

    const groupTable = this.$refs.keywordGroupTable as BTable

    if (groupTable) {
      const keywordTable = this.$refs[`keywordTable${item.keywordGroupId}`] as BTable

      if (groupTable.isRowSelected(index)) {
        groupTable.unselectRow(index)

        if (keywordTable) {
          keywordTable.clearSelected()
        }

        return
      }

      if (keywordTable) {
        keywordTable.selectAllRows()
      }

      groupTable.selectRow(index)
    }
  }

  onKeywordSelected (item: TableItem, index: number) {
    this.toggleKeyword(item.keywordId as number)
    const keywordTable = this.$refs[`keywordTable${item.keywordGroupId}`] as BTable

    if (keywordTable) {
      if (keywordTable.isRowSelected(index)) {
        keywordTable.unselectRow(index)

        return
      }

      keywordTable.selectRow(index)
    }
  }

  isGroupIndeterminate (keywordGroupId: number) {
    const group = find(this.caConfig.getKeywordGroups(), { id: keywordGroupId })

    if (group) {
      let hasSelected = false
      let hasUnselected = false

      for (const keyword of group.getKeywords()) {
        if (keyword.getStatus() === InfinityStatus.Ok) {
          if (this.selectedKeywords.includes(keyword)) {
            hasSelected = true

            continue
          }

          hasUnselected = true
        }
      }

      return hasSelected && hasUnselected
    }

    return false
  }

  toggleGroup (keywordGroupId: number) {
    const group = find(this.caConfig.getKeywordGroups(), { id: keywordGroupId })

    if (group) {
      if (this.selectedGroups.includes(group)) {
        pull(this.selectedGroups, group)

        for (const keyword of group.getKeywords()) {
          this.removeKeyword(keyword)
        }

        return
      }

      this.selectedGroups.push(group)
      for (const keyword of group.getKeywords()) {
        this.selectKeyword(keyword)
      }
    }
  }

  selectKeyword (keyword: KeywordConfig) {
    if (this.selectedKeywords.includes(keyword) === false && keyword.getStatus() === InfinityStatus.Ok) {
      this.selectedKeywords.push(keyword)

      if (this.selectedGroups.includes(keyword.getKeywordGroup()) === false) {
        this.selectedGroups.push(keyword.getKeywordGroup())
      }
    }
  }

  removeKeyword (keyword: KeywordConfig) {
    pull(this.selectedKeywords, keyword)
  }

  toggleKeyword (keywordId: number) {
    const keyword = find(this.caConfig.getAllKeywordsConfig(), { id: keywordId })

    if (keyword) {
      if (this.selectedKeywords.includes(keyword)) {
        this.removeKeyword(keyword)

        return
      }

      this.selectKeyword(keyword)
    }
  }

  get canSave () {
    return this.selectedKeywords.length > 0 && this.selectedInstallation.getId()
  }

  async doSearchInstallations (query: string) {
    if (query) {
      this.isLoading = true

      const repo = new InstallationRepo()

      this.installations = await repo.fetch(
        new FilterCollection(
          new Filter('igrpName', FilterOperand.IncludesI, query),
          new Filter('igrp', FilterOperand.NotEquals, AuthUtil.installationId as string),
          new Filter('conversationAnalytics', FilterOperand.Equals, '1')
        )
      )

      this.isLoading = false
    }
  }

  prepareConfigForSave () {
    this.transferConfig.setInstallationId(parseInt(this.selectedInstallation.getId()))
    for (const group of this.selectedGroups) {
      const spottingConfig = new KeywordSpottingConfig()
      const newGroup = new KeywordGroupConfig()
        .setIsNew(true)
        .setName(group.getName())
        .setType(group.getType())
        .setDescription(group.getDescription())
        .setStatus(group.getStatus())

      for (const keyword of group.getKeywords()) {
        if (this.selectedKeywords.includes(keyword)) {
          newGroup.addKeyword(
            new KeywordConfig()
              .setKeywordCriteria(keyword.getKeywordCriteria())
              .setPhrase(keyword.getPhrase())
              .setScore(keyword.getScore())
              .setMinConfidence(keyword.getMinConfidence())
              .setLeg(keyword.getLeg())
              .setKeywordGroup(newGroup)
              .setStatus(keyword.getStatus())
          )
        }
      }

      spottingConfig.setKeywordGroup(newGroup)
      const caSpottingConfig = this.transferConfig.getKeywordSpottingConfig()
      caSpottingConfig.push(spottingConfig)
      this.transferConfig.setKeywordSpottingConfig(caSpottingConfig)
    }
  }

  async doSave () {
    const repo = new KeywordTransferRepo(this.selectedInstallation.getId())
    this.isSaving = true
    this.prepareConfigForSave()

    try {
      await repo.postBulk(this.transferConfig.getKeywordSpottingConfig())

      this.$emit('complete')
    } catch (e) {
      this.$bvToast.toast('We encountered a problem transferring these keywords. Please check that the receiving Installation does not have any conflicting groups and try again.', {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 5000
      })
    }

    this.isSaving = false
  }

  beforeDestroy () {
    this.reset()
  }

  reset () {
    this.selectedInstallation = new Installation()
    this.transferConfig = new CaConfig()
    this.selectedKeywords = []
    this.selectedGroups = []
    this.installationQuery = ''
  }
}
