import Model, { modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class BulkRedactionRequest extends Model {
  protected fieldDefinition = {
    [Api.CaService]: {
      all: 'all',
      trackingPools: 'trackingPools'
    }
  }

  private all = true
  private trackingPools: number[] = []
  private pciRedaction = false
  private ssnRedaction = false
  private pinRedaction = false

  getAll (): boolean {
    return this.all
  }

  setAll (value: boolean) {
    this.all = value

    return this
  }

  getTrackingPools (): number[] {
    return this.trackingPools
  }

  setTrackingPools (value: number[]) {
    this.trackingPools = value

    return this
  }

  getPciRedaction (): boolean {
    return this.pciRedaction
  }

  setPciRedaction (value: boolean) {
    this.pciRedaction = value

    return this
  }

  getSsnRedaction (): boolean {
    return this.ssnRedaction
  }

  setSsnRedaction (value: boolean) {
    this.ssnRedaction = value

    return this
  }

  getPinRedaction (): boolean {
    return this.pinRedaction
  }

  setPinRedaction (value: boolean) {
    this.pinRedaction = value

    return this
  }

  fromApiTransformer (): BulkRedactionRequest {
    return this
  }

  toApiTransformer (api: Api): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition[api])

    data.redactionSettings = {
      pciRedaction: this.pciRedaction,
      ssnRedaction: this.ssnRedaction,
      pinRedaction: this.pinRedaction
    }

    return data
  }
}
