
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  BBadge,
  BCard,
  BFormCheckbox,
  CollapsePlugin,
  LayoutPlugin,
  OverlayPlugin,
  SkeletonPlugin, ToastPlugin
} from 'bootstrap-vue'
import ConfigCreate from '@infinity/ca-tester/components/ConfigCreate.vue'
import KeywordSpottingConfig from '@infinity/ca-tester/models/keywordSpottingConfig'
import { mixins } from 'vue-class-component'
import WebComponentAuthMixin from '@infinity/shared/mixins/web-component-auth'
import { PermissionMask } from '@infinity/shared/models/profile'
import KeywordTransfer from '@/components/KeywordTransfer.vue'

Vue.use(LayoutPlugin)
Vue.use(CollapsePlugin)
Vue.use(OverlayPlugin)
Vue.use(SkeletonPlugin)
Vue.use(ToastPlugin)

@Component({
  components: {
    ConfigCreate,
    BCard,
    BFormCheckbox,
    BBadge,
    KeywordTransfer
  }
})
export default class KeywordSpotting extends mixins(WebComponentAuthMixin) {
  @Prop({ type: Boolean, default: false })
  keywordSpottingOnly!: boolean

  isLoading = true
  isSaving = false

  isTransferMode = false

  get caConfig () {
    return this.$store.state.caConfig.compareConfig
  }

  get canTransfer () {
    return this.keywordSpottingOnly && this.$infinityAuth.getProfile().hasPermission('apInternal', PermissionMask.View)
  }

  async created () {
    await this.waitForBoot()
    if (!this.keywordSpottingOnly && this.$store.state.hasStarted === false) {
      await this.$router.push('/')
    }

    this.$mixpanelService.startTimedEvent('settings.conversationAnalytics.wizard.viewKeywordConfig', {}, true)

    await this.loadConfig()
  }

  async loadConfig () {
    this.isLoading = true

    await this.$store.dispatch('caConfig/loadBaseConfig')
    await this.$store.dispatch('caConfig/createCompareConfig')

    if (this.caConfig.getKeywordSpottingConfig().length === 0) {
      this.caConfig.setKeywordSpottingConfig([new KeywordSpottingConfig()])
    }

    this.isLoading = false
  }

  get canSave () {
    if (this.caConfig.getAllKeywordsConfig().length === 0) {
      return false
    }

    for (const keyword of this.caConfig.getAllKeywordsConfig()) {
      if (keyword.getPhrase() === '') {
        return false
      }
    }

    return true
  }

  async doSave () {
    this.isSaving = true
    await this.$store.dispatch('caConfig/saveConfig')
    this.isSaving = false

    if (!this.keywordSpottingOnly) {
      await this.$mixpanelService.trackTimedEvent('settings.conversationAnalytics.wizard.viewKeywordConfig')
      this.$store.commit('setHasCompletedKeywordConfig', true)
      await this.$router.push('/sensitive-data')
    } else {
      await this.loadConfig()
    }
  }

  onTransferComplete () {
    this.$bvToast.toast('Your keywords have successfully been transferred.', {
      title: 'Keywords transferred',
      variant: 'success',
      autoHideDelay: 5000
    })

    this.isTransferMode = false
  }

  appDeactivated () {
    this.$mixpanelService.trackTimedEvent('settings.conversationAnalytics.wizard.viewKeywordConfig')
  }
}
