import Model, { apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData, InfinityStatus } from '@infinity/shared/helpers/api'

export default class TrackingPool extends Model {
  protected fieldDefinition = {
    [Api.Hub]: {
      dgrp: 'id',
      dgrpName: 'name',
      dgrpStatus: 'status',
      callRecordingPciRedaction: 'pciRedaction',
      callRecordingSsnRedaction: 'ssnRedaction',
      callRecordingPinRedaction: 'pinRedaction'
    }
  }

  private id = 0
  private name = ''
  private status = InfinityStatus.Ok
  private pciRedaction = false
  private ssnRedaction = false
  private pinRedaction = false

  getId (): number {
    return this.id
  }

  setId (value: number | string) {
    if (typeof value === 'string') {
      value = parseInt(value)
    }

    this.id = value

    return this
  }

  getName (): string {
    return this.name
  }

  setName (value: string) {
    this.name = value

    return this
  }

  getStatus (): InfinityStatus {
    return this.status
  }

  setStatus (value: InfinityStatus | string) {
    if (typeof value === 'string') {
      value = parseInt(value)
    }

    this.status = value

    return this
  }

  getPciRedaction (): boolean {
    return this.pciRedaction
  }

  setPciRedaction (value: boolean | string) {
    if (typeof value === 'string') {
      value = value === '1'
    }

    this.pciRedaction = value

    return this
  }

  getSsnRedaction (): boolean {
    return this.ssnRedaction
  }

  setSsnRedaction (value: boolean | string) {
    if (typeof value === 'string') {
      value = value === '1'
    }

    this.ssnRedaction = value

    return this
  }

  getPinRedaction (): boolean {
    return this.pinRedaction
  }

  setPinRedaction (value: boolean | string) {
    if (typeof value === 'string') {
      value = value === '1'
    }

    this.pinRedaction = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): TrackingPool {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }
}
