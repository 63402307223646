import Vue from 'vue'
import Vuex from 'vuex'
import caConfig from '@infinity/ca-tester/store/modules/ca-config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hasStarted: false,
    hasCompletedKeywordConfig: false,
    hasCompletedRedaction: false
  },
  mutations: {
    setHasStarted (state, value: boolean) {
      state.hasStarted = value
    },
    setHasCompletedKeywordConfig (state, value: boolean) {
      state.hasCompletedKeywordConfig = value
    },
    setHasCompletedRedaction (state, value: boolean) {
      state.hasCompletedRedaction = value
    }
  },
  actions: {
  },
  modules: {
    caConfig
  }
})
