
import { Component, Emit } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import WebComponentAuthMixin from '@infinity/shared/mixins/web-component-auth'
import WebComponentRootMixin from '@infinity/shared/mixins/web-component-root'

@Component
export default class App extends mixins(WebComponentAuthMixin, WebComponentRootMixin) {
  async created () {
    await this.waitForBoot()
    this.$mixpanelService.startTimedEvent('settings.conversationAnalytics.wizard.start', {}, true)

    this.$root.$on('test-engine', this.doTestEngine)
    this.$root.$on('call-log', this.doCallLog)
  }

  beforeDestroy () {
    this.$root.$off('test-engine', this.doTestEngine)
    this.$root.$off('call-log', this.doCallLog)
  }

  startWizard () {
    this.$mixpanelService.trackTimedEvent('settings.conversationAnalytics.wizard.start')
    this.$store.commit('setHasStarted', true)
    this.$router.push('/keyword-spotting')
  }

  @Emit('test-engine')
  doTestEngine () {
    return true
  }

  @Emit('call-log')
  doCallLog () {
    return true
  }
}
