import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AuthUtil } from 'mfe-shared/app/src/utils/auth'
import KeywordSpotting from '@/views/KeywordSpotting.vue'
import Complete from '@/views/Complete.vue'
import Redaction from '@/views/Redaction.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/keyword-spotting',
    name: 'Keyword Spotting',
    component: KeywordSpotting
  },
  {
    path: '/sensitive-data',
    name: 'Sensitive Data',
    component: Redaction
  },
  {
    path: '/complete',
    name: 'Complete',
    component: Complete
  }
]

const router = new VueRouter({
  mode: 'history',
  base: AuthUtil.basePath || process.env.BASE_URL,
  routes
})

export default router
