
import { Component } from 'vue-property-decorator'
import ConfigCreate from '@infinity/ca-tester/components/ConfigCreate.vue'
import TrackingPoolRepo from '@/repos/trackingPoolRepo'
import { Filter, FilterCollection, FilterOperand } from '@infinity/ca-tester/helpers/filter'
import TrackingPool from '@/models/trackingPool'
import BulkRedactionRequest from '@/models/bulkRedactionRequest'
import BulkRedactionRepo from '@/repos/bulkRedactionRepo'
import { mixins } from 'vue-class-component'
import WebComponentAuthMixin from '@infinity/shared/mixins/web-component-auth'

@Component({
  components: {
    ConfigCreate
  }
})
export default class Redaction extends mixins(WebComponentAuthMixin) {
  isLoading = true
  isSaving = false

  showRedactionOptions = false
  hasExistingRedaction = false

  isPciSelected = false
  isSsnSelected = false
  isPinSelected = false
  isNoneSelected = false

  showTrackingPools = false

  trackingPoolQuery = ''
  trackingPools: TrackingPool[] = []
  selectedTrackingPools: TrackingPool[] = []

  trackingPoolLimit = 15

  isFetchingTrackingPools = false

  bulkRedaction = new BulkRedactionRequest()

  doSelectNone () {
    this.isPciSelected = false
    this.isSsnSelected = false
    this.isPinSelected = false
    this.isNoneSelected = !this.isNoneSelected
    this.showTrackingPools = false
    this.selectedTrackingPools = []
  }

  doSelectPci () {
    this.isPciSelected = !this.isPciSelected
    this.isNoneSelected = false
  }

  doSelectSsn () {
    this.isSsnSelected = !this.isSsnSelected
    this.isNoneSelected = false
  }

  doSelectPin () {
    this.isPinSelected = !this.isPinSelected
    this.isNoneSelected = false
  }

  get isOptionSelected () {
    return this.isPciSelected ||
      this.isSsnSelected ||
      this.isPinSelected ||
      this.isNoneSelected
  }

  selectTrackingPool (trackingPool: TrackingPool) {
    this.selectedTrackingPools.push(trackingPool)
    this.fetchTrackingPools()
  }

  removeTrackingPool (trackingPool: TrackingPool) {
    const index = this.selectedTrackingPools.indexOf(trackingPool)

    if (index > -1) {
      this.selectedTrackingPools.splice(index, 1)
      this.fetchTrackingPools()
    }
  }

  get selectedIds () {
    return this.selectedTrackingPools.map(
      (trackingPool: TrackingPool) => {
        return trackingPool.getId()
      }
    )
  }

  async created () {
    await this.waitForBoot()
    if (this.$store.state.hasCompletedKeywordConfig === false) {
      await this.$router.push('/keyword-spotting')

      return
    }

    this.$mixpanelService.startTimedEvent('settings.conversationAnalytics.wizard.viewRedaction', {}, true)

    const trackingPoolRepo = new TrackingPoolRepo()
    const existing = await trackingPoolRepo.fetch(
      new FilterCollection(
        new Filter('callRecordingPciRedaction', FilterOperand.Equals, '1')
      )
    )

    if (existing.length) {
      this.hasExistingRedaction = true
    }

    await this.fetchTrackingPools()

    this.isLoading = false
  }

  async fetchTrackingPools () {
    this.isFetchingTrackingPools = true

    const filterCollection = new FilterCollection()

    if (this.trackingPoolQuery) {
      filterCollection.addFilter(new Filter('dgrpName', FilterOperand.IncludesI, this.trackingPoolQuery))
    }

    if (this.selectedTrackingPools.length) {
      filterCollection.addFilter(new Filter('dgrp', FilterOperand.NIncludesList, this.selectedIds))
    }

    const trackingPoolRepo = new TrackingPoolRepo()
    this.trackingPools = await trackingPoolRepo.fetch(filterCollection, this.trackingPoolLimit)

    this.isFetchingTrackingPools = false
  }

  async doSave () {
    this.isSaving = true

    if (this.selectedTrackingPools.length) {
      this.bulkRedaction.setAll(false)
      this.bulkRedaction.setTrackingPools(this.selectedIds)
    }

    this.bulkRedaction.setPciRedaction(this.isPciSelected)
    this.bulkRedaction.setSsnRedaction(this.isSsnSelected)
    this.bulkRedaction.setPinRedaction(this.isPinSelected)

    const bulkRedactionRepo = new BulkRedactionRepo()
    await bulkRedactionRepo.create(this.bulkRedaction)

    this.$store.commit('setHasCompletedRedaction', true)
    this.isSaving = false

    await this.$mixpanelService.trackTimedEvent('settings.conversationAnalytics.wizard.viewRedaction')

    await this.$router.push('/complete')
  }

  keepSettings () {
    this.$store.commit('setHasCompletedRedaction', true)

    this.$mixpanelService.trackTimedEvent('settings.conversationAnalytics.wizard.viewRedaction')

    this.$router.push('/complete')
  }

  appDeactivated () {
    this.$mixpanelService.trackTimedEvent('settings.conversationAnalytics.wizard.viewRedaction')
  }
}
