import Repository, { DefaultLimit } from '@infinity/shared/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import Installation from '@/models/installation'
import { FilterCollection } from '@infinity/shared/helpers/filter'

export default class InstallationRepo extends Repository {
  constructor () {
    super(
      Api.Hub,
      'igrps',
      Installation,
      'igrps'
    )
  }

  async fetch (filterCollection: FilterCollection | null = null, limit: number = DefaultLimit, offset = 0): Promise<Installation[]> {
    const results = await super.fetch(filterCollection, limit, offset)
    const installations: Installation[] = []

    for (const result of results) {
      if (result instanceof Installation) {
        installations.push(result)
      }
    }

    return installations
  }
}
