import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
// @ts-ignore
import wrap from '@vue/web-component-wrapper'
// @ts-ignore
import VueTestAttribute from 'vue-test-attribute'
import InfinityPopover from '@infinity/shared/plugins/infinity-popover'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import InfinityServices from '@infinity/shared/plugins/infinity-services'

Vue.config.productionTip = false

Vue.use(InfinityServices)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(InfinityPopover)
Vue.use(VueTestAttribute)

Vue.mixin({ router, store })

if (window.customElements.get('ca-wizard') === undefined) {
  window.customElements.define('ca-wizard', wrap(Vue, App))
}
