import Repository, { DefaultLimit } from '@infinity/ca-tester/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import { FilterCollection } from '@infinity/ca-tester/helpers/filter'
import TrackingPool from '@/models/trackingPool'

export default class TrackingPoolRepo extends Repository {
  constructor () {
    super(
      Api.Hub,
      `config/igrps/${AuthUtil.installationId}/dgrps`,
      TrackingPool,
      'dgrps'
    )
  }

  async fetch (filterCollection: FilterCollection | null = null, limit: number = DefaultLimit, offset = 0): Promise<TrackingPool[]> {
    const data = await super.fetch(filterCollection, limit, offset)
    const models = []

    for (const item of data) {
      if (item instanceof TrackingPool) {
        models.push(item)
      }
    }

    return models
  }
}
