import Repository from '@infinity/ca-tester/repos/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import BulkRedactionRequest from '@/models/bulkRedactionRequest'

export default class BulkRedactionRepo extends Repository {
  constructor () {
    super(
      Api.CaService,
      `installation/${AuthUtil.installationId}/bulkRedaction`,
      BulkRedactionRequest
    )
  }

  async create (model: BulkRedactionRequest): Promise<JsonData | null> {
    return await super.create(model)
  }
}
